import React, { useState, ReactNode, PropsWithoutRef, useCallback, useEffect } from "react"
import { Formik, FormikProps, FormikErrors, useFormikContext } from "formik"
import * as z from "zod"
import { validateZodSchema } from "blitz"
import debounce from "lodash.debounce"
import { Flex } from "@chakra-ui/react"
import { AnimatedButton } from "./AnimatedButton"

type FormProps<FormValues> = {
  /** All your form fields */
  children: ReactNode
  /** Text to display in the submit button */
  submitText?: string
  onSubmit: (values: FormValues) => Promise<void | OnSubmitResult>
  initialValues?: FormikProps<FormValues>["initialValues"]
  schema?: z.ZodType<any, any>
  mode?: "autoSave"
  isInline?: boolean
  contentAfterSubmitButton?: any
  enableReinitialize?: boolean
} & Omit<PropsWithoutRef<JSX.IntrinsicElements["form"]>, "onSubmit">

type OnSubmitResult = {
  FORM_ERROR?: string
  [prop: string]: any
}

const AutoSave = ({ debounceMs }) => {
  const formik = useFormikContext()
  const debouncedSubmit = useCallback(debounce(formik.submitForm, debounceMs), [
    debounceMs,
    formik.submitForm,
  ])

  useEffect(() => {
    if (formik.dirty) {
      debouncedSubmit()
    }
  }, [debouncedSubmit, formik.values])

  return null
}

export const FORM_ERROR = "FORM_ERROR"

export function Form<FormValues extends Record<string, unknown>>({
  children,
  submitText,
  schema,
  initialValues,
  onSubmit,
  mode,
  isInline,
  contentAfterSubmitButton,
  enableReinitialize = false,
  ...props
}: FormProps<FormValues>) {
  const [formError, setFormError] = useState<string | null>(null)

  return (
    <Formik<FormValues>
      initialValues={initialValues || ({} as FormValues)}
      validate={validateZodSchema(schema)}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        setSubmitting(true)
        const { FORM_ERROR, ...otherErrors } = (await onSubmit(values as FormValues)) || {}
        setSubmitting(false)

        if (FORM_ERROR) {
          setFormError(FORM_ERROR)
        }

        if (Object.keys(otherErrors).length > 0) {
          setErrors(otherErrors as FormikErrors<FormValues>)
        }
      }}
      enableReinitialize={enableReinitialize}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <form onSubmit={handleSubmit} className="form" {...props}>
          {children}

          {formError && (
            <div role="alert" style={{ color: "red" }}>
              {formError}
            </div>
          )}

          {mode === "autoSave" && <AutoSave debounceMs={500} />}

          {mode !== "autoSave" && submitText && (
            <>
              <Flex mt={isInline ? 0 : 8} sx={{ justifyContent: "center" }}>
                <AnimatedButton
                  size="lg"
                  colorScheme="primary"
                  color="white"
                  type="submit"
                  {...(isSubmitting ? { disabled: true, isLoading: true } : {})}
                >
                  {submitText}
                </AnimatedButton>
              </Flex>
              {contentAfterSubmitButton ? contentAfterSubmitButton({ isSubmitting, values }) : null}
            </>
          )}
        </form>
      )}
    </Formik>
  )
}
