import { extendTheme } from "@chakra-ui/react"
import { mode } from "@chakra-ui/theme-tools"

const config = {
  initialColorMode: "light",
  // useSystemColorMode: true,
}

const theme = extendTheme({
  fonts: {
    heading: `Rubik, -apple-system, system-ui, "Segoe UI", "Helvetica Neue", sans-serif`,
    body: `Rubik, -apple-system, system-ui, "Segoe UI", "Helvetica Neue", sans-serif`,
  },
  config,
  components: {
    Button: {
      baseStyle: {
        borderRadius: 9999,
      },
      variants: {
        solid: ({ colorScheme }) => ({
          color: colorScheme === "primary" ? "white" : "inherit",
        }),
      },
    },
    Input: {
      parts: ["field", "addon"],
      variants: {
        filled: (props) => ({
          field: {
            borderRadius: 9999,
            bg: mode("gray.200", "gray.600")(props),
            color: mode("black", "white")(props),
            _focus: {
              bg: mode("gray.200", "gray.600")(props),
            },
            _hover: {
              bg: mode("gray.100", "gray.700")(props),
            },
          },
        }),
      },
    },
    Select: {
      parts: ["field", "addon"],
      variants: {
        filled: (props) => ({
          field: {
            borderRadius: 9999,
            bg: mode("gray.200", "gray.600")(props),
            _hover: {
              bg: mode("gray.100", "gray.700")(props),
            },
          },
        }),
      },
    },
  },
  colors: {
    primary: {
      50: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      100: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      200: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      300: "linear-gradient(to right top, #9e65d0, #591bd6)",
      400: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      500: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      600: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      700: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      800: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
      900: "linear-gradient(to right top, #9f5dd8, #4A00E0)",
    },
    gray: {
      ...config.gray,
      100: "#F4F5F6",
      200: "#EAEDF1",
      600: "#292C2E",
      700: "#202326",
      800: "#121517",
    },
    blue: {
      ...config.blue,
      500: "#31A7FF",
    },
    red: {
      50: "#FFF5F5",
      100: "#FED7D7",
      200: "#FEB2B2",
      300: "#FC8181",
      400: "#F56565",
      500: "#E53E3E",
      600: "#C53030",
      700: "#9B2C2C",
      800: "#822727",
      900: "#63171B",
    },
    green: {
      50: "#F0FFF4",
      100: "#C6F6D5",
      200: "#9AE6B4",
      300: "#68D391",
      400: "#48BB78",
      500: "#38A169",
      600: "#2F855A",
      700: "#276749",
      800: "#22543D",
      900: "#1C4532",
    },
    customWhite: {
      50: "#f5f5f5",
      100: "#ebebeb",
      200: "#d6d6d6",
      300: "#b8b8b8",
      400: "#999999",
      500: "#7a7a7a",
      600: "#5c5c5c",
      700: "#474747",
      800: "#292929",
      900: "#141414",
    },
  },
})

export default theme
