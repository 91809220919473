import { useEffect } from "react"
import Head from "next/head"
import {
  AppProps,
  useRouter,
  useSession,
  useQueryErrorResetBoundary,
  AuthenticationError,
  AuthorizationError,
  ErrorFallbackProps,
} from "blitz"
import { ErrorBoundary } from "blitz"
import { Flex, useColorModeValue } from "@chakra-ui/react"
import LoginForm from "app/auth/components/LoginForm"
import ErrorComponent from "app/core/components/ErrorComponent"
import Layout from "app/core/layouts/Layout"
import Sentry from "app/utils/sentry"
import { Chakra } from "app/chakra"

function Favicon() {
  const faviconPath = useColorModeValue("/favicon.svg", "/favicon-dark.svg")

  return (
    <Head>
      <link rel="shortcut icon" href={faviconPath} />
    </Head>
  )
}

export default function App({ Component, pageProps }: AppProps) {
  const session = useSession({ suspense: false })
  const getLayout = Component.getLayout || ((page) => page)
  // const router = useRouter()

  useEffect(() => {
    if (session.userId) Sentry.setUser({ id: session.userId.toString() })
  }, [session])

  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="HandheldFriendly" content="true" />
      </Head>
      <Chakra cookies={{}}>
        <Favicon />
        <ErrorBoundary
          onError={(error, componentStack) => {
            Sentry.captureException(error, { contexts: { react: { componentStack } } })
          }}
          FallbackComponent={RootErrorFallback}
          onReset={useQueryErrorResetBoundary().reset}
        >
          {getLayout(<Component {...pageProps} />)}
        </ErrorBoundary>
      </Chakra>
    </>
  )
}

const RootErrorFallback = ({ error, resetErrorBoundary }: ErrorFallbackProps) => {
  if (error instanceof AuthenticationError) {
    return (
      <Layout title="Log In">
        <Flex as="main" sx={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
          <LoginForm />
        </Flex>
      </Layout>
    )
  } else if (error instanceof AuthorizationError) {
    return (
      <ErrorComponent
        statusCode={error.statusCode}
        title="Sorry, you are not authorized to access this"
      />
    )
  } else {
    return (
      <ErrorComponent statusCode={error.statusCode || 400} title={error?.message || error?.name} />
    )
  }
}
