import { useQuery } from "blitz"
import getCurrentUser from "app/users/queries/getCurrentUser"

export const useCurrentUser = () => {
  try {
    const [user] = useQuery(getCurrentUser, null)
    return user
  } catch (error) {
    return null
  }
}
