import React from "react"
import { Link, useRouter, getAntiCSRFToken } from "blitz"
import { Box, Flex, Heading, Link as LinkChakra, Text } from "@chakra-ui/react"
import { Form, FORM_ERROR } from "app/core/components/Form"
import { TextField } from "app/core/components/TextField"
import { Login } from "app/auth/validations"
import { AnimatedButton } from "app/core/components/AnimatedButton"
import { FcGoogle } from "react-icons/fc"
import { IoLogoApple } from "react-icons/io"

export const LoginForm = () => {
  const router = useRouter()

  return (
    <div>
      <Heading sx={{ fontWeight: 800, mb: 6, textAlign: "center" }}>Welcome back!</Heading>
      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Form
          submitText="Sign In with email"
          schema={Login}
          initialValues={{ email: "", password: "" }}
          style={{ maxWidth: 320, flex: 1 }}
          contentAfterSubmitButton={({ isSubmitting, values }) => (
            <Flex sx={{ flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Flex my={3} sx={{ flex: 1, alignSelf: "stretch", position: "relative" }}>
                <Flex sx={{ flex: 1, justifyContent: "center" }}>
                  <Text px={2}>Or</Text>
                </Flex>
              </Flex>
              <Box mb={3}>
                <Link href="/api/auth/google" passHref>
                  <AnimatedButton
                    as="a"
                    size="lg"
                    leftIcon={<FcGoogle />}
                    type="submit"
                    sx={{ fontWeight: 500 }}
                    {...(isSubmitting ? { disabled: true } : {})}
                  >
                    Sign In with Google
                  </AnimatedButton>
                </Link>
              </Box>
              <Link href="/api/auth/apple" passHref>
                <AnimatedButton
                  as="a"
                  size="lg"
                  leftIcon={<IoLogoApple />}
                  type="submit"
                  sx={{ fontWeight: 500 }}
                  {...(isSubmitting ? { disabled: true } : {})}
                >
                  Sign In with Apple
                </AnimatedButton>
              </Link>
            </Flex>
          )}
          onSubmit={async (values) => {
            try {
              return fetch(`/api/auth/magiclogin/send`, {
                method: `POST`,
                body: JSON.stringify({
                  destination: values.email,
                }),
                headers: { "Content-Type": "application/json", "anti-csrf": getAntiCSRFToken() },
              })
                .then((res) => res.json())
                .then((json) => {
                  if (json.success) {
                    router.push({
                      pathname: "/check-mailbox",
                      query: { e: values.email, c: json.code },
                    })
                  }
                })
            } catch (error) {
              if (error.name === "AuthenticationError") {
                return { [FORM_ERROR]: "Sorry, those credentials are invalid" }
              } else {
                return {
                  [FORM_ERROR]:
                    "Sorry, we had an unexpected error. Please try again. - " + error.toString(),
                }
              }
            }
          }}
        >
          <TextField label="Email" name="email" placeholder="Email" />
        </Form>
      </Flex>
      <Box sx={{ mt: 6, textAlign: "center" }}>
        <Text>
          No account yet?{" "}
          <Link href="/signup">
            <LinkChakra sx={{ textDecoration: "underline" }}>Register</LinkChakra>
          </Link>
        </Text>
      </Box>
    </div>
  )
}

export default LoginForm
