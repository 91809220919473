import { useColorModeValue } from '@chakra-ui/react';

const Logo: React.FC = () => {
  const color = useColorModeValue('#000', '#fff')
  const dotColors = useColorModeValue(['#5da5f7', '#055ec4'], ['#B9EEFF', '#0073FB'])
  const opacities = useColorModeValue([1, 0.79, 0.4], [1, 0.79, 0.4]);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
    >
      <path
        fill={color}
        fillOpacity={opacities[0]}
        fillRule="evenodd"
        d="M5 25V5h20v10h5V4a4 4 0 00-4-4H4a4 4 0 00-4 4v22a4 4 0 004 4h11v-5H5z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillOpacity={opacities[1]}
        fillRule="evenodd"
        d="M10 10h10v5h5V5H5v20h10v-5h-5V10z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        fillOpacity={opacities[2]}
        fillRule="evenodd"
        d="M10 10h10v5h-3a2 2 0 00-2 2v3h-5V10z"
        clipRule="evenodd"
      ></path>

      <rect
        width="10"
        height="10"
        x="20"
        y="20"
        fill="url(#paint0_linear)"
        rx="2"
      ></rect>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="20"
          x2="33.5"
          y1="25"
          y2="25"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={dotColors[0]}></stop>
          <stop offset="1" stopColor={dotColors[1]}></stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Logo;
