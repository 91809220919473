import { ReactNode, Suspense } from "react"
import { Head, Link, useRouter, useSession, useMutation, invoke } from "blitz"
import {
  chakra,
  Box,
  Button,
  Container,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
  IconButton,
  Spinner,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"
import { BiMoon, BiSun, BiCoffeeTogo } from "react-icons/bi"
import { TiUserOutline } from "react-icons/ti"
import { IoMdLogOut } from "react-icons/io"
import { AnimatedButton } from "app/core/components/AnimatedButton"
import Logo from "app/core/components/Logo"
import logout from "app/auth/mutations/logout"
import stopImpersonating from "app/auth/mutations/stopImpersonating"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"

type LayoutProps = {
  title?: string
  noMenus?: boolean
  noHeader?: boolean
  cookies?: any
  children: ReactNode
}

export const ImpersonatingUserNotice = () => {
  const session = useSession()
  if (!session.impersonatingFromUserId) return null

  return (
    <Flex sx={{ height: "50px", backgroundColor: "#F2CE49" }}>
      <Container
        maxW="container.lg"
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Flex
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            color: "black",
            fontWeight: "bold",
            width: "100%",
          }}
        >
          Currently impersonating user {session.userId}{" "}
          <Button
            size="sm"
            sx={{ ml: 2 }}
            onClick={async () => {
              await invoke(stopImpersonating, {})
              // queryClient.clear()
            }}
          >
            Stop
          </Button>
        </Flex>
      </Container>
    </Flex>
  )
}

const NavbarWrapper = (props) => {
  const Wrapper = chakra(Flex, {
    baseStyle: {
      minHeight: 75,
      pt: 3,
      pb: 5,
      position: "fixed",
      left: 0,
      right: 0,
      zIndex: 2,
    },
  })

  const bg = useColorModeValue(
    "linear-gradient(rgba(255, 255, 255), rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.001))",
    "linear-gradient(rgba(18, 21, 21), rgba(18, 21, 21, 0.8), rgba(18, 21, 21, 0.001))"
  )

  return <Wrapper sx={{ bg }} {...props} />
}

const ProfileMenu = () => {
  const router = useRouter()
  const { colorMode, toggleColorMode } = useColorMode()
  const [logoutMutation] = useMutation(logout)
  const currentUser = useCurrentUser()

  return (
    <Menu placement="bottom-end" closeOnSelect={false}>
      <MenuButton
        as={IconButton}
        fontSize="24px"
        variant="ghost"
        aria-label="User Profile"
        icon={<TiUserOutline />}
      />
      <MenuList>
        <MenuItem onClick={() => router.push("/profile")}>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            Settings
          </Flex>
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => toggleColorMode()}>
          {colorMode === "light" ? (
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Text>Enable dark mode</Text> <BiMoon />
            </Flex>
          ) : (
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Text>Enable light mode</Text> <BiSun />
            </Flex>
          )}
        </MenuItem>
        <MenuItem
          onClick={() => {}}
          data-feedback-fish
          {...(currentUser ? { "data-feedback-fish-userid": currentUser.email } : {})}
        >
          Send feedback
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={() => window.open("https://www.buymeacoffee.com/matthieuh", "_blank")}>
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Text>Buy founder a coffee</Text> <BiCoffeeTogo />
          </Flex>
        </MenuItem>
        <MenuDivider />
        <MenuItem
          onClick={() =>
            logoutMutation().then(() => {
              router.push("/")
            })
          }
        >
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <strong>Logout</strong> <Icon as={IoMdLogOut} />
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

const Layout = ({ title, noMenus = false, noHeader = false, children }: LayoutProps) => {
  const router = useRouter()
  const session = useSession({ suspense: false })

  return (
    <Box sx={{ position: "relative", height: "100vh" }}>
      <Head>
        <title>{title || "Budg"}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Suspense fallback={null}>
        <ImpersonatingUserNotice />
      </Suspense>

      {!noHeader && (
        <NavbarWrapper>
          <Container
            maxW="container.lg"
            sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
          >
            <Flex sx={{ alignItems: "center" }}>
              {!["/", "/subscriptions"].includes(router.pathname) && !noMenus && (
                <AnimatedButton onClick={() => router.back()}>Back</AnimatedButton>
              )}

              {["/", "/subscriptions"].includes(router.pathname) && !noMenus && (
                <a href="https://budg.co">
                  <Flex as="a" sx={{ alignItems: "center" }}>
                    <Logo />
                  </Flex>
                </a>
              )}
            </Flex>

            <Flex sx={{ alignItems: "center" }}>
              {!["/profile"].includes(router.pathname) && !!session?.userId && (
                <Suspense
                  fallback={
                    <Flex
                      sx={{
                        width: "40px",
                        height: "40px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner size="sm" colorScheme="blue" />
                    </Flex>
                  }
                >
                  <ProfileMenu />
                </Suspense>
              )}

              {((router.pathname == "/" && !session.userId && !session.isLoading) || noMenus) && (
                <Flex>
                  <Link href="/login" passHref>
                    <AnimatedButton as="a" mr={4}>
                      Sign in
                    </AnimatedButton>
                  </Link>
                  <Link href="/signup" passHref>
                    <AnimatedButton as="a" colorScheme="primary">
                      Sign up
                    </AnimatedButton>
                  </Link>
                </Flex>
              )}
            </Flex>
          </Container>
        </NavbarWrapper>
      )}

      {children}

      <style jsx global>{`
        body {
          margin: 0;
          font-family: Untitled Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
            "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
          -webkit-tap-highlight-color: transparent;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          line-height: 1;
        }

        svg {
          display: block;
          vertical-align: middle;
        }

        html,
        body,
        #root,
        #__next,
        #__next > div,
        .theme-0 {
          height: 100%;
        }

        #__next {
          position: relative;
          z-index: 0;
        }
      `}</style>
    </Box>
  )
}

export default Layout
