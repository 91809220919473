import React, { PropsWithoutRef, useState } from "react"
import { useField, useFormikContext, ErrorMessage } from "formik"
import { Box, Flex, Input, InputProps, Text } from "@chakra-ui/react"
import DatePicker from "react-datepicker"

import "react-datepicker/dist/react-datepicker.css"
import styles from "./TextField.module.css"

export interface TextFieldProps extends PropsWithoutRef<InputProps> {
  /** Field name. */
  name: string
  /** Field label. */
  label?: string
  isInline?: boolean
  /** Field type. Doesn't include radio buttons and checkboxes */
  type?: "text" | "password" | "email" | "number" | "tel" | "date"
  disabled?: boolean
  outerProps?: any
  outerCss?: any
  sx?: any
}

export const TextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ name, label, type, isInline = false, outerCss = {}, outerProps, ...restProps }, ref) => {
    const [input, meta, helpers] = useField(name)
    const [dateValue, setDateValue] = useState(input.value)
    const { isSubmitting } = useFormikContext()

    const CustomInput = React.forwardRef((props, refProp) => (
      <Input
        {...props}
        ref={refProp as any}
        variant="filled"
        sx={{
          width: "100%",
          ...restProps.sx,
        }}
        {...input}
        disabled={isSubmitting}
      />
    ))

    return (
      <Box
        {...outerProps}
        sx={{
          flex: 1,
          ...(isInline ? { display: "flex", mr: 3 } : { flexDirection: "column", mb: 4 }),
          ...outerCss,
        }}
      >
        {!!label && (
          <Text fontWeight={500} mb={2}>
            {label}
          </Text>
        )}

        <Flex>
          {type === "date" ? (
            <DatePicker
              wrapperClassName={styles.datePickerWrapper}
              selected={dateValue}
              onChange={(date) => {
                setDateValue(date)
                helpers.setValue(date)
              }}
              autoComplete="off"
              placeholderText={restProps.placeholder}
              customInput={<CustomInput />}
            />
          ) : (
            <Input
              ref={ref}
              variant="filled"
              sx={{
                width: "100%",
                ...restProps.sx,
              }}
              {...input}
              disabled={isSubmitting}
              type={type}
              {...restProps}
            />
          )}
        </Flex>

        <ErrorMessage name={name}>
          {(msg) => (
            <Box role="alert" sx={{ color: "red.600", ml: 4, mb: "1rem" }}>
              {msg}
            </Box>
          )}
        </ErrorMessage>
      </Box>
    )
  }
)
