import { motion } from "framer-motion"
import { Button } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

const MotionButton = motion(Button)

const waitingAnimation = keyframes`
  100% {
    transform: translateX(85px);
  }
`

const Waiting = styled.div({
  position: "absolute",
  top: 0,
  left: "-100%",
  width: "200%",
  height: "100%",
  backgroundImage: `linear-gradient(
            -45deg,
            transparent 33%,
            hsla(206,10%,76%, 0.1) 33%,
            hsla(206,10%,76%, 0.1) 66%,
            transparent 66%
          )`,
  backgroundSize: `85px 45px`,
  animation: `${waitingAnimation} 500ms linear infinite`,
})

export function AnimatedButton(props) {
  return (
    <MotionButton
      whileHover={{
        scale: 0.95,
        transition: { duration: 0.1 },
      }}
      whileTap={{ scale: 0.9 }}
      {...props}
    >
      {props.children}
      {props?.state === "waiting" && <Waiting />}
    </MotionButton>
  )
}
